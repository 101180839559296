import './App.css';
import Login from './components/Login';
import React from 'react'

function App() {
  return (
    <React.Fragment>
      <Login/>
    </React.Fragment>
  );
}

export default App;
